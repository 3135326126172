<template>
  <body class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper">
       
           <router-view></router-view>
          
    </div>
  </body>
</template>

<script>

import $ from 'jquery'



export default {
  name: "App",
  components: {



  },
  methods: {
    
  },
  mounted() {
    
  },

};

</script>


<style>

</style>