import { createRouter, createWebHistory } from "vue-router";
// import { onlySuperAdmin, allAdmin, onlyAdmin, onlyMd, onlyHr, onlyIT, onlyAccount, onlyGeneralEmployee, exceptGeneralEmployee,
//   onlyDepartmentHead, onlyHrAndMd, onlyDeptHeadHrAndMd, inArray, checkAuth } from '../common/common.js';

// routing - define path, name
const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children:[
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../components/Dashboard.vue'),
      }
    ]
  },

  {
    path: "/users",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/users",
        name: "users",
        component: () => import("../components/User/User.vue"),
      },
    ],
  },


  {
    path: '/paymentType',
    
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
        path: '/paymentType',
        name: 'paymentType',
        component: () => import('../components/PaymentType/PaymentType.vue'),
      }
    ]
  },

  {
    path: '/paymentTerm',
    
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
        path: '/paymentTerm',
        name: 'paymentTerm',
        component: () => import('../components/PaymentTerm/PaymentTerm.vue'),
      }
    ]
  },


  {
    path: "/gender",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/gender",
        name: "gender",
        component: () => import("../components/Gender/Gender.vue"),
      },
    ],
  },

  {
    path: "/identificationType",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/identificationType",
        name: "identificationType",
        component: () =>
          import("../components/IdentificationType/IdentificationType.vue"),
      },
    ],
  },

  {
    path: "/maritialStatus",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/maritialStatus",
        name: "maritialStatus",
        component: () =>
          import("../components/MaritialStatus/MaritialStatus.vue"),
      },
    ],
  },

  {
    path: "/religion",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/religion",
        name: "religion",
        component: () => import("../components/Religion/Religion.vue"),
      },
    ],
  },

  {
    path: "/bloodGroup",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/bloodGroup",
        name: "bloodGroup",
        component: () => import("../components/BloodGroup/BloodGroup.vue"),
      },
    ],
  },



  {
    path: "/employmentType",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/employmentType",
        name: "Employment Type",
        component: () => import("../components/EmploymentType.vue"),
      },
    ],
  },
  {
    path: "/companyWings",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/companyWings",
        name: "Company Wings",
        component: () => import("../components/CompanyWings.vue"),
      },
    ],
  },
  {
    path: "/workinghour",
    component: () => import("../Layout/Dashboardlayout.vue"),   
    children: [
      {
        path: "/workinghour",
        name: "workinghour",
        component: () => import("../components/WorkingHour.vue"),
      },
    ],
  },
  {
    path: "/authorizedSignature",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/authorizedSignature",
        name: "Authorized Signature",
        component: () => import("../components/AuthorizedSignature.vue"),
      },
    ],
  },
  {
    path: "/department",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/department",
        name: "Department",
        component: () => import("../components/Department.vue"),
      },
    ],
  },
  {
    path: "/grade",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/grade",
        name: "Grade",
        component: () => import("../components/Grade/Grade.vue"),
      },
    ],
  },
  {
    path: "/section",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/section",
        name: "Section",
        component: () => import("../components/Section.vue"),
      },
    ],
  },
  {
    path: "/holiday",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/holiday",
        name: "Holiday",
        component: () => import("../components/Holiday.vue"),
      },
    ],
  },
  {
    path: "/employmentstatus",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/employmentstatus",
        name: "Employment Status",
        component: () => import("../components/EmploymentStatus.vue"),
      },
    ],
  },
  {
    path: "/recruitmenttype",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/recruitmenttype",
        name: "Recruitment Type",
        component: () => import("../components/RecruitmentType.vue"),
      },
    ],
  },

  {
    path: "/designation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/designation",
        name: "designation",
        component: () => import("../components/Designation.vue"),
      },
    ],
  },

  {
    path: "/leavetype",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leavetype",
        name: "leavetype",
        component: () => import("../components/LeaveType.vue"),
      },
    ],
  },
  {
    path: "/leaveapplication",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leaveapplication",
        name: "leaveapplication",
        component: () => import("../components/LeaveApplication.vue"),
      },
    ],
  },
  

  {
    path: '/employee',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/employee',
      name: 'employee',
      component: () => import('../components/Employees.vue'),
      }
    ]
  },
  {
    path: "/editEmpInfo",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/editEmpInfo",
        name: "EditEmployeeInformation",
        component: () => import("../components/EditEmployeeInfo.vue"),
      },
    ],
  },
  {
    path: '/idCards',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/idCards',
      name: 'idCards',
      component: () => import('../components/IdCards.vue'),
      }
    ]
  },

  {
    path: '/retiredEmployees',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/retiredEmployees',
      name: 'retiredEmployees',
      component: () => import('../components/RetiredEmployee.vue'),
      }
    ]
  },

  
  {
    path: '/retirement',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/retirement',
      name: 'Retirement',
      component: () => import('../components/Retirement.vue'),
      }
    ]
  },
  {
    path: '/attendance',  
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/attendance',
      name: 'Attendance',
      component: () => import('../components/Attendance/Attendance.vue'),
      }
    ]
  },
  { 
    path: '/overtimeCalculation',  
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/overtimeCalculation',
      name: 'Overtime Calculation',
      component: () => import('../components/Attendance/OvertimeCalculation.vue'),
      }
    ]
  },
  { 
    path: '/generateTotalOvertime',  
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/generateTotalOvertime',
      name: 'Generate Total Overtime',
      component: () => import('../components/Attendance/GenerateTotalOvertime.vue'),
      }
    ]
  },
  {
    path: '/roster',  
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/roster',
      name: 'Roster',
      component: () => import('../components/Attendance/Roster.vue'),
      }
    ]
  },
  {
    path: '/attendanceIntegration',  
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/attendanceIntegration',
      name: 'Attendance Integration',
      component: () => import('../components/Attendance/AttendanceIntegration.vue'),
      }
    ]
  },
  {
    path: '/pendingAttendanceList',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/pendingAttendanceList',
      name: 'Pending Attendance List',
      component: () => import('../components/Attendance/PendingAttendanceList.vue'),
      }
    ]
  },
  {
    path: '/attendanceLogReport',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/attendanceLogReport',
      name: 'Attendance Log Report',
      component: () => import('../components/Attendance/AttendanceLogReport.vue'),
      }
    ]
  },
  {
    path: '/specialLeavePermission',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/specialLeavePermission',
      name: 'Special Leave Permission',
      component: () => import('../components/SpecialLeavePermission.vue'),
      }
    ]
  },
  {
    path: '/attendanceHistory',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/attendanceHistory',
      name: 'Attendance History',
      component: () => import('../components/Attendance/AttendanceLogReport.vue'), 
      }
    ]
  },
  {
    path: '/attendanceReport',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/attendanceReport',
      name: 'Attendance Report',
      component: () => import('../components/Attendance/AttendanceReport.vue'),
      }
    ]
  },
  {
    path: '/authorizedDelayPendingList',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/authorizedDelayPendingList',
      name: 'Authorized Delay Pending List',
      component: () => import('../components/Attendance/AuthorizedDelay.vue'),
      }
    ]
  },  
  {
    path: '/setting',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/setting',
      name: 'Setting',
      component: () => import('../components/Setting/Setting.vue'),
      }
    ]
  },
  {
    path: '/organizationOrganogram',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/organizationOrganogram',
      name: 'organizationOrganogram',
      component: () => import('../components/OrganizationOrganogram.vue'),
      }
    ]
  },
  {
    path: '/applyAuthorizedDelay',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/applyAuthorizedDelay',
      name: 'Apply Authorized Delay',
      component: () => import('../components/Attendance/ApplyAuthorizedDelay.vue'),
      }
    ]
  },
  {
    path: '/continuousLateReport',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/continuousLateReport',
      name: 'Continuous Late Report',
      component: () => import('../components/Attendance/ContinuousLateReport.vue'),
      }
    ]
  },
  {
    path: '/absentReport',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/absentReport',
      name: 'Absent Report',
      component: () => import('../components/Attendance/AbsentReport.vue'),
      }
    ]
  },
  {
    path: '/nocType',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/nocType',
      name: 'Noc Type',
      component: () => import('../components/Noc/NocType.vue'),
      }
    ]
  },
  {
    path: '/noc',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/noc',
      name: 'NocComp',
      component: () => import('../components/Noc/NocComp.vue'),
      }
    ]
  },
  {
    path: '/outTimeMissing',   
    component: () => import('../Layout/Dashboardlayout.vue'),    
    children:[
      {
      path: '/outTimeMissing',
      name: 'Out Time Missing',
      component: () => import('../components/Attendance/OutTimeMissing.vue'),
      }
    ]
  },
  {
    path: '/probationEmpList',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/probationEmpList',
      name: 'Probation Employee List',
      component: () => import('../components/ProbationaryEmployeeAssessmentFormForConfirmation/ProbationEmpList.vue'),
      }
    ]
  },
  {
    path: '/empServiceLength',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/empServiceLength',
      name: 'Employee Service Length',
      component: () => import('../components/EmpServiceLength.vue'),
      }
    ]
  },
  {
    path: "/approvedleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/approvedleave",
        name: "approvedleave",
        component: () => import("../components/ApprovedLeaves.vue"),
      },
    ],
  },
  {
    path: "/forwardListForHr",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/forwardListForHr",
        name: "Forward List For Hr",
        component: () => import("../components/ForwardListForHr.vue"),
      },
    ],
  },
  {
    path: "/checkedListForHr",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/checkedListForHr",
        name: "Checked List For Hr",
        component: () => import("../components/CheckedListForHr.vue"),
      },
    ],
  },
  {
    path: "/rosterSummary",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/rosterSummary",
        name: "rosterSummary",
        component: () => import("../components/Roster/RosterSummary.vue"),
      },
    ],
  },
  {
    path: "/leavereport",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leavereport",
        name: "leavereport",
        component: () => import("../components/LeaveReport.vue"),
      },
    ],
  },
  {
    path: "/checkEmpLeave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/checkEmpLeave",
        name: "checkEmpLeave",
        component: () => import("../components/CheckEmployeeLeave.vue"),
      },
    ],
  },
  {
    path: "/pendingleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/pendingleave",
        name: "pendingleave",
        component: () => import("../components/PendingLeaves.vue"),
      },
    ],
  },
  {
    path: "/forwardedleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/forwardedleave",
        name: "forwardedleave",
        component: () => import("../components/ForwardedLeaves.vue"),
      },
    ],
  },

  {
    path: "/transfertype",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/transfertype",
        name: "transfertype",
        component: () => import("../components/TransferType/TransferType.vue"),
      },
    ],
  },
  {
    path: "/transferEmployee",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/transferEmployee",
        name: "transferEmployee",
        component: () => import("../components/Transfer/SearchEmployeeInformation.vue"),
      },
    ],
  },
  {
    path: "/transferHistory",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/transferHistory",
        name: "Transfer History",
        component: () => import("../components/Transfer/TransferHistory.vue"),
      },
    ],
  },
  {
    path: "/companyBranch",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/companyBranch",
        name: "Company Branch",
        component: () => import("../components/CompanyBranch/CompanyBranch.vue"),
      },
    ],
  },
  {
    path: "/departmentHead",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/departmentHead",
        name: "departmentHead",
        component: () => import("../components/DepartmentHead/DepartmentHead.vue"),
      },
    ],
  },


  {
    path: "/resignaedList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignaedList",
        name: "resignaedList",
        component: () => import("../components/Resignation/ResignaedList.vue"),
      },
    ],
  },
  {
    path: "/applyResignation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/applyResignation",
        name: "applyResignation",
        component: () => import("../components/Resignation/ApplyResignation.vue"),
      },
    ],
  },

  {
    path: "/resignationPendingList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingList",
        name: "resignationPendingList",
        component: () => import("../components/Resignation/ResignationPendingList.vue"),
      },
    ],
  },

  {
    path: "/dismissEmployeeList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/dismissEmployeeList",
        name: "dismissEmployeeList",
        component: () => import("../components/Resignation/DismissEmpList.vue"),
      },
    ],
  },



  {
    path: "/probationaryEmployeeAssessmentFormForConfirmation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/probationaryEmployeeAssessmentFormForConfirmation",
        name: "probationaryEmployeeAssessmentFormForConfirmation",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ProbationaryEmployeeAssessmentFormForConfirmation.vue"),
      },
    ],
  },

  {
    path: "/confirmationList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationList",
        name: "confirmationList",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationList.vue"),
      },
    ],
  },
  {
    path: "/confirmationReportForManagingDirector",  

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationReportForManagingDirector",
        name: "confirmationReportForManagingDirector",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationReportForManagingDirector.vue"),
      },
    ],
  },
  {
    path: "/confirmationReportForHr",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationReportForHr",
        name: "confirmationReportForHr",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationReportForHr.vue"),
      },
    ],
  },
  {
    path: "/evaluationEntry",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/evaluationEntry",
        name: "Evaluation Entry",
        component: () => import("../components/Evaluation/EvaluationEntry.vue"),
      },
    ],
  },
  {
    path: "/evaluationList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/evaluationList",
        name: "Evaluation List",
        component: () => import("../components/Evaluation/EvaluationList.vue"),
      },
    ],
  },
  {
    path: "/evaluationReportForHR",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/evaluationReportForHR",
        name: "Evaluation Report For HR",
        component: () => import("../components/Evaluation/EvaluationReportForHR.vue"),
      },
    ],
  },
  
  {
    path: "/cashSheet",  

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/cashSheet",
        name: "Cash Sheet",
        component: () => import("../components/Salary/CashSheet.vue"),
      },
    ],
  },
  {
    path: "/bankSheet",  

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/bankSheet",
        name: "Bank Sheet",
        component: () => import("../components/Salary/BankSheet.vue"),
      },
    ],
  },
  {
    path: "/salarySummary",  

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/salarySummary",
        name: "Salary Summary",
        component: () => import("../components/Salary/SalarySummary.vue"),
      },
    ],
  },
  {
    path: "/applyPromotion",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/applyPromotion",
        name: "applyPromotion",
        component: () => import("../components/Promotion/ApplyPromotion.vue"),
      },
    ],
  },

  {
    path: "/promotionPendingList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/promotionPendingList",
        name: "promotionPendingList",
        component: () => import("../components/Promotion/PromotionPendingList.vue"),
      },
    ],
  },
  {
    path: "/promotionForwordedList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/promotionForwordedList",
        name: "promotionForwordedList",
        component: () => import("../components/Promotion/PromotionForwordedList.vue"),
      },
    ],
  },

  {
    path: "/promotionApprovedList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/promotionApprovedList",
        name: "promotionApprovedList",
        component: () => import("../components/Promotion/PromotionApprovedList.vue"),
      },
    ],
  },
  {
    path: "/promotionDeclinedList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/promotionDeclinedList",
        name: "promotionDeclinedList",
        component: () => import("../components/Promotion/PromotionDeclinedList.vue"),
      },
    ],
  },


  {
    path: "/salaryInfoEntry",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/salaryInfoEntry",
        name: "SalaryInfoEntry",
        component: () => import("../components/Salary/SalaryInfoEntry.vue"),
      },
    ],
  },

  {
    path: "/salaryGenerate",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/salaryGenerate",
        name: "SalaryGenerate",
        component: () => import("../components/Salary/SalaryGenerate.vue"),
      },
    ],
  },

  {
    path: "/salaryReport",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/salaryReport",
        name: "SalaryReport",
        component: () => import("../components/Salary/SalaryReport.vue"),
      },
    ],
  },

  {
    path: "/incrementEntry",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/incrementEntry",
        name: "IncrementEntry",
        component: () => import("../components/Increment/IncrementEntry.vue"),
      },
    ],
  },

  {
    path: "/changeLeaveRequestApplication",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/changeLeaveRequestApplication",
        name: "change Leave Request Application",
        component: () => import("../components/ChangeLeaveRequest/ChangeLeaveRequestApplication.vue"),
      },
    ],
  },

  {
    path: "/changeLeavePendingList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/changeLeavePendingList",
        name: "Change Leave Pending List",
        component: () => import("../components/ChangeLeaveRequest/ChangeLeavePendingList.vue"),
      },
    ],
  },

  {
    path: "/bonusGenerate",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/bonusGenerate",
        name: "BonusGenerate",
        component: () => import("../components/Bonus/BonusGenerate.vue"),
      },
    ],
  },

  {
    path: "/taxGenerate",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/taxGenerate",
        name: "TaxGenerate",
        component: () => import("../components/Tax/TaxGenerate.vue"),
      },
    ],
  },

  {
    path: "/officeScheduleShifting",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/officeScheduleShifting",
        name: "Office Schedule Shifting",
        component: () => import("../components/Roster/OfficeScheduleShifting.vue"),
      },
    ],
  },
  {
    path: "/officeSchedule",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/officeSchedule",
        name: "Office Schedule",
        component: () => import("../components/OfficeSchedule/OfficeSchedule.vue"),
      },
    ],
  },

  {
    path: "/designationTransportFee",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/designationTransportFee",
        name: "Designation Transport Fee",
        component: () => import("../components/TransportSalary/DesignationTransportFee.vue"),
      },
    ],
  },

  {
    path: "/employeeTransportRecords",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/employeeTransportRecords",
        name: "Employee Transport Record",
        component: () => import("../components/TransportSalary/EmployeeTransportRecords.vue"),
      },
    ],
  },

  {
    path: "/checkApplyLeave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/checkApplyLeave",
        name: "Check Apply Leave",
        component: () => import("../components/CheckApplyLeave.vue"),   
      },
    ],
  },

  {
    path: "/checkApplyLeaveFromChangeRequest",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/checkApplyLeaveFromChangeRequest",
        name: "Check Apply Leave From Change Request",
        component: () => import("../components/CheckApplyLeaveFromChangeRequest.vue"),    
      },
    ],
  },

  {
    path: "/specialLeaveList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/specialLeaveList",
        name: "Special Leave List",
        component: () => import("../components/SpecialLeaveList.vue"),    
      },
    ],
  },
  // Deduction
  {
    path: "/absentDeduction",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/absentDeduction",
        name: "Absent Deduction",
        component: () => import("../components/Deduction/AbsentDeduction.vue"),    
      },
    ],
  },

  {
    path: "/mobileBillDeduction",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/mobileBillDeduction",
        name: "Mobile Bill Deduction",
        component: () => import("../components/Deduction/MobileBillDeduction.vue"),    
      },
    ],
  },
  {
    path: "/taxDeduction",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/taxDeduction",
        name: "Tax Deduction",
        component: () => import("../components/Deduction/TaxDeduction.vue"),    
      },
    ],
  },
  {
    path: "/providentFundDeduction",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/providentFundDeduction",
        name: "Provident Fund Deduction",
        component: () => import("../components/Deduction/ProvidentFundDeduction.vue"),    
      },
    ],
  },
  {
    path: "/loanDeduction",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/loanDeduction",
        name: "Loan Deduction",
        component: () => import("../components/Deduction/LoanDeduction.vue"),    
      },
    ],
  },
  {
    path: "/deductionReport",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/deductionReport",
        name: "Deduction Report",
        component: () => import("../components/Deduction/DeductionReport.vue"),    
      },
    ],
  },
  {
    path: "/loanEntry",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/loanEntry",
        name: "Loan Entry",
        component: () => import("../components/Loan_Mgt/LoanEntry.vue"),    
      },
    ],
  },
  {
    path: "/loanStatement",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/loanStatement",
        name: "Loan Statement",
        component: () => import("../components/Loan_Mgt/LoanStatement.vue"),    
      },
    ],
  },
  {
    path: "/medicalAndHospitalizationBenefit",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/medicalAndHospitalizationBenefit",
        name: "Entry Medical and Hospitalization Benefit",
        component: () => import("../components/MedicalandHospitalization/MedicalandHospitalizationBenefit.vue"),    
      },
    ],
  },
  
  {
    path: "/medicalandHospitalizationBenefitStatement",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/medicalandHospitalizationBenefitStatement",
        name: "Medical and Hospitalization Benefit Statement",
        component: () => import("../components/MedicalandHospitalization/MedicalandHospitalizationBenefitStatement.vue"),    
      },
    ],
  },
  {
    path: "/medicalandHospitalizationBenefitNotification",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/medicalandHospitalizationBenefitNotification",
        name: "Medical and Hospitalization Benefit Notification",
        component: () => import("../components/MedicalandHospitalization/MedicalandHospitalizationBenefitNotification.vue"),    
      },
    ],
  },
  {
    path: "/medicalandHospitalizationBenefitLimit",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/medicalandHospitalizationBenefitLimit",
        name: "Medical and Hospitalization Benefit Limit",
        component: () => import("../components/MedicalandHospitalization/MedicalandHospitalizationBenefitLimit.vue"),    
      },
    ],
  },  
  {
    path: "/taxChallan",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/taxChallan",
        name: "Tax Challan",
        component: () => import("../components/TaxChallan/TaxChallan.vue"),    
      },
    ],
  },
  {
    path: "/providentFundSetup",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/providentFundSetup",
        name: "Provident Fund Setup",
        component: () => import("../components/ProvidentFund/ProvidentFundSetup.vue"),    
      },
    ],
  },
  {
    path: "/providentFundStatement",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/providentFundStatement",
        name: "Provident Fund Statement",
        component: () => import("../components/ProvidentFund/ProvidentFundStatement.vue"),    
      },
    ],
  },
  {
    path: "/arrearAdjustment",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/arrearAdjustment",
        name: "Arrear Adjustment",
        component: () => import("../components/Arrear/ArrearAdjustment.vue"),    
      },
    ],
  }, 
  {
    path: "/attendanceDeviceIntegration",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/attendanceDeviceIntegration",
        name: "Attendance Device Integration",
        component: () => import("../components/Integration/AttendanceDeviceIntegration.vue"),    
      },
    ],
  }, 
  {
    path: "/smsApiIntegration",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/smsApiIntegration",
        name: "SMS Api Integration",
        component: () => import("../components/Integration/SMSApiIntegration.vue"),    
      },
    ],
  }, 


  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('../components/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to,from, next) => { 
  
  window.scrollTo({
     top: 0,
     left: 0,
     behavior: 'smooth'
   });
})


router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
    next({
      path: '/',
      replace: true
    });
  } else {

    next();
  }
});

export default router;
