import { createApp } from "vue";
import App from "./App.vue";
//import Vue from 'vue'
import axios from "axios";
import VueAxios from "vue-axios";
import Toaster from "@meforma/vue-toaster";
import VueSweetalert2 from "vue-sweetalert2";
import naive from "naive-ui";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Popper from "vue3-popper";
import "../public/css/theme.css"
import  VueHtmlToPaper from '../plugins/VueHtmlToPaper.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueApexCharts from "vue3-apexcharts";
import VueGoodTablePlugin from 'vue-good-table-next';
import Multiselect from 'vue-multiselect';
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

import {Calendar} from 'v-calendar';
import 'v-calendar/style.css';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

//import router from './router/index.js'
import "sweetalert2/dist/sweetalert2.min.css";
//import moment from 'moment';

window.$ = window.jQuery = require("jquery");

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import { createToast } from 'mosha-vue-toastify';
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGooglePlus } from "@fortawesome/free-brands-svg-icons";



//import ckeditor
import CKEditor from "@ckeditor/ckeditor5-vue";

//import time picker
import VueTimepicker from "vue3-timepicker";
// import 'vue3-timepicker/dist/VueTimepicker.css'
// import VueTimepicker from 'vue3-timepicker/dist/VueTimepicker.cjs.js'

// import the styling for the toast
//import 'mosha-vue-toastify/dist/style.css'

//import adminlte scripts
import "../node_modules/admin-lte/dist/js/adminlte.min.js";
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js";
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js";
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js";
//mport "../node_modules/admin-lte/plugins/moment/moment.min.js"
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js";
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js";
//import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js";
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js";
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js";
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js";
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js";
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js";

//import adminlte styles
import "../node_modules/admin-lte/dist/css/adminlte.min.css";
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css";
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css";
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css";
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css";
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css";
import router from "./router";



//Data table 
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Data table print
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";



import { MDChairmain, onlySuperAdmin, allAdmin, onlyAdmin, onlyMd, onlyHr, onlyIT, onlyAccount, onlyGeneralEmployee, exceptGeneralEmployee,
    onlyDepartmentHead, onlyHrAndMd, onlyDeptHeadHrAndMd, inArray, checkAuth, checkPermission} from '../src/common/common.js';


library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch();

const app = createApp(App);  

app.use(router)
    .use(VueAxios, axios)
    .component('VueDatePicker', VueDatePicker)
    .component('VCalendar', Calendar)
    .use(Toaster)
    .use(VueSweetalert2)
    .use(VueApexCharts)
    .use(naive)
    .use(CKEditor)
    .component('v-select', vSelect)
    .use(Toast)
    .use(VueGoodTablePlugin)
    .use( VueHtmlToPaper )
    .component('multiselect', Multiselect)
    .component("Popper", Popper) 
    .component('blocks-tree',VueBlocksTree)


// Add a response interceptor
axios.interceptors.response.use(
    async function (response) {
      return response;
    },
    async function (error) {
      await console.log("test error", error.response.status);
      await console.log("test error", error.response.data.message);
      if (error.response.status === 401) {
        const storageData = JSON.parse(localStorage.getItem('user'));
        if(storageData){
          window.localStorage.removeItem('user');
        }
        router.push("/");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );


// =========== Local backend url [start] =========================

//LOCAL API 
    // app.config.globalProperties.backendUrl = 'http://10.0.6.6/bscl_hrm_backend'; 
    // app.config.globalProperties.frontendUrl= 'http://localhost:8080';

// LIVE API
    //app.config.globalProperties.backendUrl = 'http://203.76.123.197/bscl_hrm_backend';
    app.config.globalProperties.backendUrl = 'https://hrm-service.bscl.gov.bd'; 
    //app.config.globalProperties.frontendUrl= 'http://localhost:8080';
// LIVE API
    // app.config.globalProperties.subFolderName = '';
    // app.config.globalProperties.backendUrl = 'http://123.200.5.222:9003';
    //app.config.globalProperties.frontendUrl= 'http://localhost:8080';
     
// =========== Local backend url [end] ===========================

//RMD API 
    // app.config.globalProperties.backendUrl = 'https://hrmservice.rndghit.com'; 
    // app.config.globalProperties.frontendUrl= ' hrm.rndghit.com';


// Global Function

app.config.globalProperties.$filters = {
    
    fullMonthDateFormate(date){
        return moment(date).format("DD MMMM YYYY"); 
    }  
}


app.config.globalProperties.$inArray = inArray;
app.config.globalProperties.$checkAuth = checkAuth;

//Permissions
app.config.globalProperties.$onlySuperAdmin = onlySuperAdmin;
app.config.globalProperties.$allAdmin = allAdmin; 
app.config.globalProperties.$MDChairmain = MDChairmain; 
app.config.globalProperties.$onlyAdmin = onlyAdmin;
app.config.globalProperties.$onlyMd = onlyMd;
app.config.globalProperties.$onlyHr = onlyHr;
app.config.globalProperties.$onlyIT = onlyIT;
app.config.globalProperties.$onlyAccount = onlyAccount;
app.config.globalProperties.$onlyGeneralEmployee = onlyGeneralEmployee;
app.config.globalProperties.$exceptGeneralEmployee = exceptGeneralEmployee;
app.config.globalProperties.$onlyDepartmentHead = onlyDepartmentHead;
app.config.globalProperties.$onlyDeptHeadHrAndMd = onlyDeptHeadHrAndMd;
app.config.globalProperties.$onlyHrAndMd = onlyHrAndMd;

app.config.globalProperties.$checkPermission = checkPermission; 


app.mount('#app')
  
