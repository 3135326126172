
const storageData = JSON.parse(localStorage.getItem('user'));

  export const checkAuth = () => {
    if(!storageData){
      //window.location.href = "http://localhost:8080/login";
      window.location.href = "/";
    }else{
      const header = {
        headers:{
          //"content-type": "multipart/form-data",
          'Authorization': storageData.token_type +' '+ storageData.access_token
        }
      };
      return header;
    }
};


export const  inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(typeof haystack[i] == 'object') {
            if(arrayCompare(haystack[i], needle)){
              return true;
            } 
        } else {
            if(haystack[i] == needle){
              return true;
            } 
        }
    }
    return false;
}
  


// Permissions

export const onlySuperAdmin = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [1]);
  return result;
}; 

export const allAdmin = () => { // Super Admin, Admin, Hr, MD
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [1,2,3,6]);
  return result;
};

export const MDChairmain = () => { // Super Admin, Admin, Hr, MD
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [6,8]);
  return result;
};

export const onlyAdmin = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [2]);
  return result;
};

export const onlyMd = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [6]);
  return result;
};

export const onlyHr = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [3]);
  return result;
};

export const onlyIT = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [4]);
  return result;
};

export const onlyAccount = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [5]);
  return result;
};

export const onlyGeneralEmployee = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [7]);
  return result;
};

export const exceptGeneralEmployee = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [1,2,3,4,5,6]);
  return result;
};

export const onlyDepartmentHead = () => { 
  if(!storageData){
    return false;
  }
  if (storageData.user.department_head_status === 1) {
    return true;
  } else { 
    return false;
  } 
} 

export const onlyHrAndMd = () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [3, 6]);
  return result;
};

export const onlyDeptHeadHrAndMd= () => {
  if(!storageData){
    return false;
  }
  var result = inArray(storageData.user.role_id, [3,6]) || storageData.user.department_head_status;
  return result;
};


export const checkPermission = (module, permission) => {

  if(!storageData){
    return false;
  }

  var permissionArray = storageData.user.component_permission;
  var parsePermissionArray = JSON.parse(permissionArray)


 var roleId = storageData.user.role_id;

  if (parsePermissionArray.hasOwnProperty(module)) {
    return parsePermissionArray[module];
  } else {
    //window.location.href = "/dashboard";
    return false;
  }

};




    

  